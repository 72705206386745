jQuery(document).ready(function ($) {

    $(".subscription-form input[name='email']").on("focus", function () {
        $(".alert-subscription").addClass("d-none").removeClass("alert-success").removeClass("alert-danger").html('')
    })

    $(".subscription-form").on('submit', function (e) {
        e.preventDefault();
        var type = $(this).find('input[name="type"').val();
        var recaptcha = $("#recaptcha").val();
        $(this).find('.subscription-button').attr('disabled', true).find('.subscription-spinner').removeClass('d-none');
        $(this).ajaxSubmit({
            data: {
              recaptcha: recaptcha  
            },
            success: function (res) {
                generateCaptcha()
                var data = JSON.parse(res)
                var alertClass;
                if (data.type == "error") {
                    alertClass = "alert-danger";
                }
                if (data.type == "success") {
                    alertClass = "alert-success";
                    if(typeof gtag_report_conversion === 'function') {
                        gtag_report_conversion();
                    }
                }
                $(".alert-" + type).addClass(alertClass).html(data.message).removeClass("d-none")
                $('.subscription-button').attr('disabled', false).find('.subscription-spinner').addClass('d-none');
            }
        });       

    })
})